@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600&display=swap');

.app {
  display: block;
  margin: 4vh 5vw;
  height: 100%;
  position: relative;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  color: #000;
  overflow: hidden;
}

.app::-webkit-scrollbar {
  display: none;
}

.app {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

nav {
  margin: 5vh 0 3vh 0;
  display: flex;
  flex-direction: row;
  width: 100%;
}

nav > .column-left {
  width: 15%;
}

nav > .column-right:hover {
}

nav > .column-right > .list-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 10px;
}

nav > .column-right > .list-actions > button::before {
  content: '→';
  margin-right: 4px;
  font-weight: 500;
  color: #ff4800;
}

nav > .column-right > .list-actions > button {
  border: none;
  background: transparent;
  width: 150px;
  font-weight: 500;
  text-align: left;
  font-size: 1rem !important;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

nav > .column-right > .list-actions > button:hover {
  cursor: pointer;
  color: #ff4800;
}

nav > .column-left >  ul > li > a {
  font-weight: 500;
}

a:hover {
  color: #ff4800;
}

a {
  color: inherit;
  text-decoration: none;
}

a.activeLink {
  color: #ff4800;
}

ul {
  padding: 0;
  margin: 0;
}

p {
  margin: 1vh 0 0.5vh 0;
}

li {
  list-style: none;
}

h3 {
  margin: 3vh 0 0.5vh 0;
  font-size: 1rem;
}

.title {
  /* text-decoration: overline; */
  /* padding-left: 3px; */
}

.page {
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
