.loading {
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ff4800;
}


.list {
  width: 100%;
  height: 60vh;
  overflow: scroll;
}

.list:hover {
  cursor: default;
}

.list-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 98%;
  text-align: left;
}

.list-column {
  display: flex;
  flex-direction: column;
  align-self: center;
  flex-basis: 100%;
  flex: 1;
  text-align: left;
}

.isWide {
  flex-grow: 2;
  /* background-color: aqua; */
}

.isNarrow {
  flex-grow: 1;
  /* background-color: cornflowerblue; */
}

.NA {
  opacity: 0;
}

.headers {
  margin-bottom: 1vh;
}

.headers > .list-column {
  /* border: 1px solid #00c; */
  /* font-weight: 500; */
  border-bottom: 1px solid;
  padding: 1vh 0;
  text-align: left;
}

.headers:hover {
  border: none;
  padding: 0;
}

.entries  > .list-column {
  /* border: 1px solid #f0af0e; */
  text-align: left;
}

.entries.list-row {
  padding: 1px 3px;
}

#list:hover {
  cursor: s-resize;
}


.entries.list-row:hover {
  /* border-top: 1px solid #ff4800;
  border-bottom: 1px solid #ff4800; */
  color: #fff;
  background-color: #ff4800;
  border-radius: 3px;
  font-weight: 500;
}

.pagination {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin-top: 3vh;
}

.pagination > li > a {
  padding: 12px 5px;
}

.pagination__link:hover {
  color: #ff4800;
}

.pagination__link--active {
  background-color: #ff4800;
  color: #fff;
  border-radius: 5px;
}
.pagination__link--active:hover {
  color: #ff4800;
  background-color: #fff;
  border: 1px solid #ff4800;
}

.pagination__link--hidden {
  display: none;
}

/* Mobile */

.mobile-list {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
}

.mobile-list-element {
  /* border-bottom: 1px solid rgba(0,0,0,0.1); */
  padding: 5px;
}

.mobile-list-element:nth-child(odd) {
  background: #ddd;
}

.mobile-list-element-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: start;
}

.mobile-list-header {
  font-weight: 500;
  min-width: 120px;
}

.mobile-list-value {
  text-align: left;
  max-width: 200px;
}