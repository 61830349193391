.mapboxgl-ctrl-logo, .mapboxgl-ctrl-attrib-inner {
  display: none !important;
}

.mapContainer {
    width: 100%;
}

#container {
  margin-top: 5vh;
  width: 90vw;
  height: 62vh;
  display: flex;
}

  #infoBox {
    display: none;
    padding: 3px;
  }

.mapContainer  > h3, .mapContainer  > p {
    font-size: 0.9rem;
  }

@media only screen and (max-width: 1000px) {
  .mapContainer {
    width: 70%;
  }

  #infoBox {
    display: block;
    width: 25%;
    margin-left: 10px;
  }

}