@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mapboxgl-ctrl-logo, .mapboxgl-ctrl-attrib-inner {
  display: none !important;
}

.mapContainer {
    width: 100%;
}

#container {
  margin-top: 5vh;
  width: 90vw;
  height: 62vh;
  display: flex;
}

  #infoBox {
    display: none;
    padding: 3px;
  }

.mapContainer  > h3, .mapContainer  > p {
    font-size: 0.9rem;
  }

@media only screen and (max-width: 1000px) {
  .mapContainer {
    width: 70%;
  }

  #infoBox {
    display: block;
    width: 25%;
    margin-left: 10px;
  }

}
.loading {
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ff4800;
}


.list {
  width: 100%;
  height: 60vh;
  overflow: scroll;
}

.list:hover {
  cursor: default;
}

.list-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 98%;
  text-align: left;
}

.list-column {
  display: flex;
  flex-direction: column;
  align-self: center;
  flex-basis: 100%;
  flex: 1 1;
  text-align: left;
}

.isWide {
  flex-grow: 2;
  /* background-color: aqua; */
}

.isNarrow {
  flex-grow: 1;
  /* background-color: cornflowerblue; */
}

.NA {
  opacity: 0;
}

.headers {
  margin-bottom: 1vh;
}

.headers > .list-column {
  /* border: 1px solid #00c; */
  /* font-weight: 500; */
  border-bottom: 1px solid;
  padding: 1vh 0;
  text-align: left;
}

.headers:hover {
  border: none;
  padding: 0;
}

.entries  > .list-column {
  /* border: 1px solid #f0af0e; */
  text-align: left;
}

.entries.list-row {
  padding: 1px 3px;
}

#list:hover {
  cursor: s-resize;
}


.entries.list-row:hover {
  /* border-top: 1px solid #ff4800;
  border-bottom: 1px solid #ff4800; */
  color: #fff;
  background-color: #ff4800;
  border-radius: 3px;
  font-weight: 500;
}

.pagination {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin-top: 3vh;
}

.pagination > li > a {
  padding: 12px 5px;
}

.pagination__link:hover {
  color: #ff4800;
}

.pagination__link--active {
  background-color: #ff4800;
  color: #fff;
  border-radius: 5px;
}
.pagination__link--active:hover {
  color: #ff4800;
  background-color: #fff;
  border: 1px solid #ff4800;
}

.pagination__link--hidden {
  display: none;
}

/* Mobile */

.mobile-list {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
}

.mobile-list-element {
  /* border-bottom: 1px solid rgba(0,0,0,0.1); */
  padding: 5px;
}

.mobile-list-element:nth-child(odd) {
  background: #ddd;
}

.mobile-list-element-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: start;
}

.mobile-list-header {
  font-weight: 500;
  min-width: 120px;
}

.mobile-list-value {
  text-align: left;
  max-width: 200px;
}
.app {
  display: block;
  margin: 4vh 5vw;
  height: 100%;
  position: relative;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  color: #000;
  overflow: hidden;
}

.app::-webkit-scrollbar {
  display: none;
}

.app {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

nav {
  margin: 5vh 0 3vh 0;
  display: flex;
  flex-direction: row;
  width: 100%;
}

nav > .column-left {
  width: 15%;
}

nav > .column-right:hover {
}

nav > .column-right > .list-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 10px;
}

nav > .column-right > .list-actions > button::before {
  content: '→';
  margin-right: 4px;
  font-weight: 500;
  color: #ff4800;
}

nav > .column-right > .list-actions > button {
  border: none;
  background: transparent;
  width: 150px;
  font-weight: 500;
  text-align: left;
  font-size: 1rem !important;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

nav > .column-right > .list-actions > button:hover {
  cursor: pointer;
  color: #ff4800;
}

nav > .column-left >  ul > li > a {
  font-weight: 500;
}

a:hover {
  color: #ff4800;
}

a {
  color: inherit;
  text-decoration: none;
}

a.activeLink {
  color: #ff4800;
}

ul {
  padding: 0;
  margin: 0;
}

p {
  margin: 1vh 0 0.5vh 0;
}

li {
  list-style: none;
}

h3 {
  margin: 3vh 0 0.5vh 0;
  font-size: 1rem;
}

.title {
  /* text-decoration: overline; */
  /* padding-left: 3px; */
}

.page {
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

